import React from "react";
import Nav from "./nav";
import Stepbar from "./stepBar";
const Layout = (props) => {
  return (
    <div>
      <Nav locale={props.locale} changeLocale={props.changeLocale} />
      <div className='overall'>
        <Stepbar
          stepHandler={props.stepHandler}
          values={props.values}
          step={props.step}
        />
        <div className='container'>{props.children}</div>
      </div>
    </div>
  );
};

export default Layout;
