import React, { useState } from "react";
import UserForm from "./components/UserForm";
import "./App.css";
import { I18n } from "react-polyglot";
import messages from "./messages";

const App = () => {
  const [locale, setLocale] = useState("tm");

  const changeLocale = () => {
    if (locale === "tm") {
      setLocale("ru");
      localStorage.setItem("localeOfBank", "ru");
    }
    if (locale === "ru") {
      localStorage.setItem("localeOfBank", "tm");
      setLocale("tm");
    }
  };

  return (
    <div className='App'>
      <I18n locale={locale} messages={messages[locale]}>
        <UserForm changeLocale={changeLocale} locale={locale} />
      </I18n>
    </div>
  );
};

export default App;
