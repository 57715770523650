import React, { Component, Fragment } from "react";
import classes from "./input.module.css";
import NumberFormat from "react-number-format";
import { translate } from "react-polyglot";

class Step4 extends Component {
  state = {
    activeNameIndex: 0,
    inputFocused: false,
  };

  onKeyDownHandler = (key) => {
    switch (key.keyCode) {
      case 13:
        if (this.props.nameSuggestions.length) {
          this.props.contactPersonSelected(
            this.props.nameSuggestions[this.state.activeNameIndex].name
          );
          this.setState({ inputFocused: false });
        }
        break;
      case 40:
        key.preventDefault();
        if (
          this.state.activeNameIndex <
          this.props.nameSuggestions.length - 1
        ) {
          this.setState({
            activeNameIndex: this.state.activeNameIndex + 1,
          });
        }
        break;
      case 38:
        key.preventDefault();
        if (this.state.activeNameIndex > 0) {
          this.setState({
            activeNameIndex: this.state.activeNameIndex - 1,
          });
        }
        break;
      default:
        this.setState((state) => ({
          inputFocused: true,
          activeNameIndex: 0,
        }));
        break;
    }
  };
  continue = (e) => {
    e.preventDefault();
    if (
      this.props.values.degree.valid &&
      this.props.values.maritalStatus.valid &&
      this.props.values.contactPerson.valid &&
      this.props.values.contactPersonNumber.valid
    ) {
      this.props.nextStep();
    } else {
      this.props.isStep4Valid();
    }
  };
  render() {
    const {
      values,
      handleChange,
      prevStep,
      academicDegree,
      maritalStatus,
      contactPersonSelected,
      nameSuggestions,
      contactPerson,
    } = this.props;
    const invalid = (control) => {
      const inputClasses = [classes.InputElement, "small3"];
      if (values[control].touched) {
        if (!values[control].valid) {
          inputClasses.push(classes.Invalid);
          return inputClasses.join(" ");
        }
        return inputClasses.join(" ");
      }
      return inputClasses.join(" ");
    };

    return (
      <Fragment>
        <form>
          <div className="row m-5">
            <div className="col">
              <span className="small float-left">
                {this.props.t("degree")}:*
              </span>
              <select
                className={invalid("degree")}
                onChange={handleChange("degree")}
                value={values.degree.value}
              >
                <option value="" hidden>
                  {this.props.t("choose")}
                </option>
                {academicDegree.map((m, i) => (
                  <option value={m.slug} key={i}>
                    {m[this.props.locale]}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <span className="small float-left">
                {this.props.t("maritalStatus")}:*
              </span>
              <select
                className={invalid("maritalStatus")}
                onChange={handleChange("maritalStatus")}
                value={values.maritalStatus.value}
              >
                <option value="" hidden>
                  {this.props.t("choose")}
                </option>
                {maritalStatus.map((m, i) => (
                  <option value={m.slug} key={i}>
                    {m[this.props.locale]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <p style={{ color: "black" }}>{this.props.t("emergencyPerson")}</p>
          <div className="row m-5">
            <div className="col">
              <span className="small float-left">{this.props.t("name")}:*</span>

              <div className="inputContainer">
                <input
                  type="text"
                  value={values.contactPerson.value}
                  className={invalid("contactPerson")}
                  onChange={contactPerson}
                  onFocus={() => {
                    this.setState({ inputFocused: true });
                  }}
                  onBlur={(e) => {
                    this.setState({ inputFocused: false });
                  }}
                  onKeyDown={this.onKeyDownHandler}
                />
                <div
                  className="suggestions"
                  style={{
                    display: this.state.inputFocused ? "block" : "none",
                  }}
                >
                  <ul>
                    {nameSuggestions.map((item, i) => (
                      <li
                        key={i}
                        onMouseDown={() => {
                          contactPersonSelected(item.name);
                        }}
                        style={{
                          backgroundColor:
                            this.state.activeNameIndex === i ? "#eee" : "",
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* <AsyncCreatableSelect
                placeholder={this.props.t("likeInPassportName")}
                formatCreateLabel={(inputValue) => inputValue}
                name="contactPerson"
                noOptionsMessage={() => this.props.t("notFound")}
                loadingMessage={() => this.props.t("loading")}
                styles={customStyles}
                isClearable={true}
                value={values.contactPerson.objValue}
                cacheOptions
                defaultOptions
                loadOptions={nameAsyncLoad}
                onChange={(e) => {
                  contactPerson(e);
                }}
              /> */}
            </div>
            <div className="col">
              <span className="small float-left">
                {this.props.t("emergencyNumber")}:*
              </span>
              <NumberFormat
                className={invalid("contactPersonNumber")}
                onChange={handleChange("contactPersonNumber")}
                value={values.contactPersonNumber.value}
                displayType={"number"}
                format="+(993) 6#-##-##-##"
                allowEmptyFormatting
                mask={"_"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-primary mx-3"
                onClick={prevStep}
              >
                {this.props.t("previous")}
              </button>
              <button
                type="button"
                className="btn btn-primary mx-3"
                onClick={this.continue}
              >
                {this.props.t("next")}
              </button>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default translate()(Step4);
