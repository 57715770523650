import React, { Component, Fragment } from "react";
import * as _ from "lodash";
import axios from "axios";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Success from "./success";
import Nav from "./nav";

import Layout from "./layout";
class UserForm extends Component {
  state = {
    controls: {
      firstName: {
        value: "",
        validation: {
          required: true,
          minLength: 2,
        },
        valid: false,
        touched: false,
      },
      lastName: {
        value: "",
        validation: {
          required: true,
          minLength: 2,
        },
        valid: false,
        touched: false,
      },
      patronym: {
        value: "",
        validation: {},
        valid: true,
        touched: false,
      },
      phoneNumber: {
        value: "",
        validation: {
          required: true,
          isPhone: true,
          // minLength: 11,
          // maxLength: 11,
        },
        valid: false,
        touched: false,
      },
      email: {
        value: "",
        validation: {},
        valid: true,
        touched: false,
      },
      passportSerial: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
      },
      passportNumber: {
        value: "",
        validation: {
          required: true,
          minLength: 6,
          maxLength: 6,
          isNumeric: true,
        },
        valid: false,
        touched: false,
      },
      issuedDate: {
        value: "",
        touched: false,
        valid: false,
        validation: {
          dateRequired: true,
        },
      },
      birthDate: {
        value: "",
        touched: false,
        valid: false,
        validation: {
          dateRequired: true,
        },
      },
      destrict: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
      },
      bankBranch: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
      },
      workStartDate: {
        value: "",
        touched: false,
        valid: false,
        validation: {
          dateRequired: true,
        },
      },
      occupation: {
        value: "",
        validation: {
          required: true,
          minLength: 3,
        },
        valid: false,
        touched: false,
      },
      workDistrict: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
      },
      workRegion: {
        value: "",
        code: null,
        validation: {
          required: true,
        },
        valid: false,
      },
      workAddress: {
        value: "",
        validation: {
          required: true,
          minLength: 5,
        },
        valid: false,
        touched: false,
      },
      salary: {
        value: "",
        validation: {
          required: true,
          maxLength: 5,
        },
        valid: false,
        touched: false,
      },
      workPhoneNumber: {
        value: "",
        validation: {
          required: true,
          homePhone: true,
        },
        valid: false,
        touched: false,
      },
      degree: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
      },
      maritalStatus: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
      },
      contactPerson: {
        value: "",
        validation: {
          required: true,
          minLength: 3,
        },
        valid: false,
        touched: false,
      },
      contactPersonNumber: {
        value: "",
        validation: {
          required: true,
          isPhone: true,
        },
        valid: false,
        touched: false,
      },
      creditType: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
      },
    },
    step: 1,
    formIsValid: false,
    nameSuggestions: [],
    surnameSuggestions: [],
    middleSuggestions: [],
    passport: [],
    states: [],
    maritalStatus: [],
    academicDegree: [],
    creditTypes: [],
    branches: [],
    loadingBranches: false,
    districtList: [],
    regionLoading: false,
    regionCode: null,
    addressSuggestions: [],
    creditLoading: false,
    creditObject: null,
    contact: {},
  };

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_ARSLAN}api/form`)
      .then((res) => {
        this.setState({
          passport: res.data.passport,
          states: res.data.states,
          maritalStatus: res.data.maritalStatus,
          academicDegree: res.data.academicDegree,
          creditTypes: res.data.creditTypes,
        });
      })
      .catch((e) => {});
  }

  addressChanged = async (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        workAddress: {
          ...this.state.controls.workAddress,
          value: e.target.value,
          valid: this.checkValidity(
            e.target.value,
            this.state.controls.workAddress.validation
          ),
          touched: true,
        },
      },
    });
    const res = await axios.get(
      `${process.env.REACT_APP_ARSLAN}api/work/${e.target.value}`
    );
    const suggestions = _.take(res.data.data, 6);
    this.setState({
      addressSuggestions: suggestions,
    });
  };

  addressSelected = async (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        workAddress: {
          ...this.state.controls.workAddress,
          value: e,
          valid: this.checkValidity(
            e,
            this.state.controls.workAddress.validation
          ),
          touched: true,
        },
      },
    });
  };

  nameChanged = async (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        firstName: {
          ...this.state.controls.firstName,
          value: e.target.value,
          valid: this.checkValidity(
            e.target.value,
            this.state.controls.firstName.validation
          ),
          touched: true,
        },
      },
      nameSuggestions: [],
    });
    const res = await axios.get(
      `${process.env.REACT_APP_ARSLAN}api/name/${e.target.value}`
    );
    const suggestions = _.take(res.data.data, 6);
    this.setState({
      nameSuggestions: suggestions,
    });
  };

  nameSelected = (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        firstName: {
          ...this.state.controls.firstName,
          value: e,
          valid: this.checkValidity(
            e,
            this.state.controls.firstName.validation
          ),
          touched: true,
        },
      },
    });
  };

  // nameAsyncLoad = (e) => {
  //   return axios
  //     .get(`${process.env.REACT_APP_ARSLAN}api/name/${e}`)
  //     .then((res) => {
  //       const suggestions = _.take(res.data.data, 6);
  //       const newsugg = suggestions.map(({ name }) => {
  //         return {
  //           value: name,
  //           label: name,
  //         };
  //       });
  //       return newsugg;
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  surnameChanged = async (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        lastName: {
          ...this.state.controls.lastName,
          value: e.target.value,
          valid: this.checkValidity(
            e.target.value,
            this.state.controls.lastName.validation
          ),
          touched: true,
        },
      },
    });
    const res = await axios.get(
      `${process.env.REACT_APP_ARSLAN}api/surname/${e.target.value}`
    );
    const suggestions = _.take(res.data.data, 6);
    this.setState({
      surnameSuggestions: suggestions,
    });
  };

  surnameSelected = (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        lastName: {
          ...this.state.controls.lastName,
          value: e,
          valid: this.checkValidity(e, this.state.controls.lastName.validation),
          touched: true,
        },
      },
    });
  };

  middleName = async (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        patronym: {
          ...this.state.controls.patronym,
          value: e.target.value,
          valid: this.checkValidity(
            e.target.value,
            this.state.controls.patronym.validation
          ),
          touched: true,
        },
      },
    });
    const res = await axios.get(
      `${process.env.REACT_APP_ARSLAN}api/middlename/${e.target.value}`
    );
    const suggestions = _.take(res.data.data, 6);
    this.setState({
      middleSuggestions: suggestions,
    });
  };

  middleNameSelected = (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        patronym: {
          ...this.state.controls.patronym,
          value: e,
          valid: this.checkValidity(e, this.state.controls.patronym.validation),
          touched: true,
        },
      },
    });
  };

  contactPerson = async (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        contactPerson: {
          ...this.state.controls.contactPerson,
          value: e.target.value,
          valid: this.checkValidity(
            e.target.value,
            this.state.controls.contactPerson.validation
          ),
          touched: true,
        },
      },
      nameSuggestions: [],
    });
    const res = await axios.get(
      `${process.env.REACT_APP_ARSLAN}api/name/${e.target.value}/`
    );
    const suggestions = _.take(res.data.data, 6);
    this.setState({
      nameSuggestions: suggestions,
    });
  };

  contactPersonSelected = (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        contactPerson: {
          ...this.state.controls.contactPerson,
          value: e,
          valid: this.checkValidity(
            e,
            this.state.controls.contactPerson.validation
          ),
          touched: true,
        },
      },
    });
  };
  isStep1Valid = () => {
    this.setState({
      controls: {
        ...this.state.controls,
        firstName: {
          ...this.state.controls.firstName,
          touched: true,
        },
        lastName: {
          ...this.state.controls.lastName,
          touched: true,
        },
        phoneNumber: {
          ...this.state.controls.phoneNumber,
          touched: true,
        },
      },
    });
  };

  isStep2Valid = () => {
    this.setState({
      controls: {
        ...this.state.controls,
        passportSerial: {
          ...this.state.controls.passportSerial,
          touched: true,
        },
        passportNumber: {
          ...this.state.controls.passportNumber,
          touched: true,
        },
        destrict: {
          ...this.state.controls.destrict,
          touched: true,
        },
        bankBranch: {
          ...this.state.controls.bankBranch,
          touched: true,
        },
        birthDate: {
          ...this.state.controls.birthDate,
          touched: true,
        },
        issuedDate: {
          ...this.state.controls.issuedDate,
          touched: true,
        },
      },
    });
  };

  isStep3Valid = () => {
    this.setState({
      controls: {
        ...this.state.controls,
        workStartDate: {
          ...this.state.controls.workStartDate,
          touched: true,
        },
        occupation: {
          ...this.state.controls.occupation,
          touched: true,
        },
        workDistrict: {
          ...this.state.controls.workDistrict,
          touched: true,
        },
        workRegion: {
          ...this.state.controls.workRegion,
          touched: true,
        },
        workAddress: {
          ...this.state.controls.workAddress,
          touched: true,
        },
        salary: {
          ...this.state.controls.salary,
          touched: true,
        },
        workPhoneNumber: {
          ...this.state.controls.workPhoneNumber,
          touched: true,
        },
      },
    });
  };

  isStep4Valid = () => {
    this.setState({
      controls: {
        ...this.state.controls,
        degree: {
          ...this.state.controls.degree,
          touched: true,
        },
        maritalStatus: {
          ...this.state.controls.maritalStatus,
          touched: true,
        },
        contactPerson: {
          ...this.state.controls.contactPerson,
          touched: true,
        },
        contactPersonNumber: {
          ...this.state.controls.contactPersonNumber,
          touched: true,
        },
      },
    });
  };

  isStep5Valid = () => {
    this.setState({
      controls: {
        ...this.state.controls,
        creditType: {
          ...this.state.controls.creditType,
          touched: true,
        },
      },
    });
  };

  checkValidity(value, rules) {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.dateRequired) {
      const newValue = Date.parse(value);
      if (isNaN(newValue)) {
        isValid = false;
      } else {
        isValid = true;
      }
    }

    if (rules.homePhone) {
      const str = value.slice(9, 21);
      const c = str.replace(/_/g, "");
      const d = c.replace(/-/g, "");
      const b = d.replace(/ /g, "");
      isValid = b.length === 8 && isValid;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.isPhone) {
      const str = value.slice(7, 18);
      const d = str.replace(/-/g, "");
      isValid = +d > 60999999 && +d < 66000000 && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  }

  nextStep = () => {
    this.setState({
      step: this.state.step + 1,
    });
  };

  prevStep = () => {
    this.setState({
      step: this.state.step - 1,
    });
  };

  districtHandle = (event) => {
    this.setState({
      loadingBranches: true,
      branches: [],
      controls: {
        ...this.state.controls,
        destrict: {
          ...this.state.controls.destrict,
          valid: true,
          value: event.target.value,
        },
        bankBranch: {
          ...this.state.controls.bankBranch,
          value: "",
          valid: false,
        },
      },
    });
    axios
      .get(`${process.env.REACT_APP_ARSLAN}api/branch/${event.target.value}`)
      .then((res) => {
        this.setState({ branches: res.data.data, loadingBranches: false });
      });
  };

  handleChange = (input) => (event) => {
    const updatedControls = {
      ...this.state.controls,
      [input]: {
        ...this.state.controls[input],
        value: event.target.value,
        valid: this.checkValidity(
          event.target.value,
          this.state.controls[input].validation
        ),
        touched: true,
      },
    };

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }

    this.setState({ controls: updatedControls, formIsValid: formIsValid });
  };

  stepHandler = (s) => {
    this.setState({ step: s });
  };

  stateChanged = (e) => {
    this.setState({
      regionLoading: true,
      regionCode: null,
      controls: {
        ...this.state.controls,
        workDistrict: {
          ...this.state.controls.workDistrict,
          valid: true,
          value: e.target.value,
        },
        workRegion: {
          ...this.state.controls.workRegion,
          value: "",
          valid: false,
        },
        workPhoneNumber: {
          ...this.state.controls.workPhoneNumber,
          value: "",
        },
      },
    });
    axios
      .get(`${process.env.REACT_APP_ARSLAN}api/district/${e.target.value}`)
      .then((res) => {
        this.setState({ districtList: res.data.data, regionLoading: false });
      });
  };

  regionChanged = (e) => {
    const code = this.state.districtList.filter((m) => {
      return m.slug === e.target.value;
    })[0].code;

    this.setState({
      regionCode: code,
      controls: {
        ...this.state.controls,
        workRegion: {
          ...this.state.controls.workRegion,
          value: e.target.value,
          valid: true,
        },
        workPhoneNumber: {
          ...this.state.controls.workPhoneNumber,
          value: "",
          valid: false,
        },
      },
    });
  };

  creditSelected = (e) => {
    this.setState({
      creditLoading: true,
      controls: {
        ...this.state.controls,
        creditType: {
          ...this.state.controls.creditType,
          value: e.target.value,
          valid: true,
        },
      },
    });
    axios
      .get(`${process.env.REACT_APP_ARSLAN}api/credit/${e.target.value}`)
      .then((res) => {
        this.setState({ creditObject: res.data, creditLoading: false });
      });
  };

  issuedDateHandler = (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        issuedDate: {
          ...this.state.controls.issuedDate,
          value: e,
          touched: true,
          valid: this.checkValidity(
            e,
            this.state.controls.issuedDate.validation
          ),
        },
      },
    });
  };

  birthDateHandler = (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        birthDate: {
          ...this.state.controls.birthDate,
          value: e,
          valid: this.checkValidity(
            e,
            this.state.controls.birthDate.validation
          ),
          touched: true,
        },
        issuedDate: {
          ...this.state.controls.issuedDate,
          value: "",
          valid: false,
        },
      },
    });
  };

  workStartDateHandler = (e) => {
    this.setState({
      controls: {
        ...this.state.controls,
        workStartDate: {
          ...this.state.controls.workStartDate,
          value: e,
          valid: this.checkValidity(
            e,
            this.state.controls.workStartDate.validation
          ),
          touched: true,
        },
      },
    });
  };

  contactHandler = (a, b, c) => {
    const newObject = {
      ...a,
      branch: c,
      orderNumber: b,
    };

    this.setState({ contact: newObject });
  };

  render() {
    const customStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? "#ccc"
            : null,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? "#ccc"
              ? "gray"
              : "black"
            : "black",
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor: !isDisabled && (isSelected ? "#ccc" : "#ccc"),
          },
        };
      },
      menu: (provided, state) => ({
        ...provided,
        color: state.selectProps.menuColor,
        // padding: 20,
      }),

      control: (provided, state) => ({
        display: "flex",
        background: "transparent",
        borderRadius: 0,
        border: 0,
        borderBottom: "1px solid",
        borderBottomColor:
          this.state.controls[state.selectProps.name].touched &&
          !this.state.controls[state.selectProps.name].valid
            ? "red"
            : "black",
        "&:hover": { borderColor: "black" },
        color: "black",
        width: "100%",
        padding: "6px 10px",
      }),
      placeholder: (provided, state) => ({
        display: "none",
      }),

      input: (provided, state) => ({
        color: "black",
      }),
      clearIndicator: (provided, state) => ({
        ...provided,
        margin: 0,
        padding: 0,
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
        margin: 0,
      }),

      dropdownIndicator: (provided, state) => ({
        display: "none",
      }),

      container: (provided, state) => ({
        ...provided,
        outline: state.isFocused ? "none" : "none",
        border: 0,
      }),

      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
      }),

      singleValue: (provided, state) => ({
        opacity: state.isDisabled ? 0.5 : 1,
        transition: "opacity 300ms",
        color: "black",
      }),
    };

    switch (this.state.step) {
      case 1:
        return (
          <Layout
            locale={this.props.locale}
            changeLocale={this.props.changeLocale}
            stepHandler={this.stepHandler}
            values={this.state.controls}
            step={this.state.step}
          >
            <Step1
              middleNameSelected={this.middleNameSelected}
              customStyles={customStyles}
              nameSelected={this.nameSelected}
              isStep1Valid={this.isStep1Valid}
              locale={this.props.locale}
              middleName={this.middleName}
              middleSuggestions={this.state.middleSuggestions}
              nameChanged={this.nameChanged}
              surnameChanged={this.surnameChanged}
              surnameSelected={this.surnameSelected}
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              values={this.state.controls}
              disabled={this.state.formIsValid}
              nameSuggestions={this.state.nameSuggestions}
              surnameSuggestions={this.state.surnameSuggestions}
            />
          </Layout>
        );
      case 2:
        return (
          <Layout
            locale={this.props.locale}
            changeLocale={this.props.changeLocale}
            stepHandler={this.stepHandler}
            values={this.state.controls}
            step={this.state.step}
          >
            <Step2
              isStep2Valid={this.isStep2Valid}
              locale={this.props.locale}
              birthDateHandler={this.birthDateHandler}
              issuedDateHandler={this.issuedDateHandler}
              districtHandle={this.districtHandle}
              passport={this.state.passport}
              states={this.state.states}
              branches={this.state.branches}
              loadingBranches={this.state.loadingBranches}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={this.state.controls}
              disabled={this.state.formIsValid}
            />
          </Layout>
        );
      case 3:
        return (
          <Layout
            locale={this.props.locale}
            changeLocale={this.props.changeLocale}
            stepHandler={this.stepHandler}
            values={this.state.controls}
            step={this.state.step}
          >
            <Step3
              addressAsyncLoad={this.addressAsyncLoad}
              customStyles={customStyles}
              regionCode={this.state.regionCode}
              isStep3Valid={this.isStep3Valid}
              locale={this.props.locale}
              workStartDateHandler={this.workStartDateHandler}
              birthday={this.state.controls.birthDate.value}
              addressChanged={this.addressChanged}
              addressSelected={this.addressSelected}
              addressSuggestions={this.state.addressSuggestions}
              code={this.state.regionCode}
              regionChanged={this.regionChanged}
              states={this.state.states}
              stateChanged={this.stateChanged}
              districtList={this.state.districtList}
              regionLoading={this.state.regionLoading}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={this.state.controls}
              disabled={this.state.formIsValid}
            />
          </Layout>
        );
      case 4:
        return (
          <Layout
            locale={this.props.locale}
            changeLocale={this.props.changeLocale}
            stepHandler={this.stepHandler}
            values={this.state.controls}
            step={this.state.step}
          >
            <Step4
              contactPersonSelected={this.contactPersonSelected}
              customStyles={customStyles}
              isStep4Valid={this.isStep4Valid}
              locale={this.props.locale}
              contactPerson={this.contactPerson}
              nameSuggestions={this.state.nameSuggestions}
              academicDegree={this.state.academicDegree}
              maritalStatus={this.state.maritalStatus}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={this.state.controls}
              disabled={this.state.formIsValid}
            />
          </Layout>
        );
      case 5:
        return (
          <Layout
            locale={this.props.locale}
            changeLocale={this.props.changeLocale}
            stepHandler={this.stepHandler}
            values={this.state.controls}
            step={this.state.step}
          >
            <Step5
              isStep5Valid={this.isStep5Valid}
              contactHandler={this.contactHandler}
              locale={this.props.locale}
              creditSelected={this.creditSelected}
              creditObj={this.state.creditObject}
              loading={this.state.creditLoading}
              credit={this.state.creditTypes}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={this.state.controls}
              disabled={this.state.formIsValid}
            />
          </Layout>
        );
      case 6:
        return (
          <Fragment>
            <Nav
              locale={this.props.locale}
              changeLocale={this.props.changeLocale}
            />
            <div className="container">
              <Success prevStep={this.prevStep} contact={this.state.contact} />
            </div>
          </Fragment>
        );
      default:
        return <h1>Nothing</h1>;
    }
  }
}

export default UserForm;
