import React, { Component, Fragment } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import classes from "./input.module.css";
import './../App.css';
import { translate } from "react-polyglot";
class Step5 extends Component {
  state = {
    modal: false,
    load: false,
  };

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  toggle = (e) => {
    if (this.props.values.creditType.valid && !this.props.loading) {
      this.setState({ modal: !this.state.modal });
    } else {
      this.props.isStep5Valid();
    }
  };

  loadTrue = () => {
    this.setState({ load: true });
  };

  loadFalse = () => {
    this.setState({ load: false });
  };

  render() {
    const { values, prevStep, credit, creditObj, loading } = this.props;
    const invalid = (control) => {
      const inputClasses = [classes.InputElement, "small3"];
      if (values[control].touched) {
        if (!values[control].valid) {
          inputClasses.push(classes.Invalid);
          return inputClasses.join(" ");
        }
        return inputClasses.join(" ");
      }
      return inputClasses.join(" ");
    };

    // const isDis = values.creditType.valid && !loading;
    return (
      <Fragment>
        {this.state.load ? (
          <div
            className="spinner-border"
            style={{
              margin: "10rem",
              width: "3rem",
              height: "3rem",
              role: "status",
              color: "black",
            }}
          >
            <span className="sr-only">{this.props.t("loading")}</span>
          </div>
        ) : (
          <div>
            <MyModal
              contactHandler={this.props.contactHandler}
              locale={this.props.locale}
              values={values}
              credit={creditObj}
              modal={this.state.modal}
              toggle={this.toggle}
              docs={this.props.t("docs")}
              print={this.props.t("print")}
              sent={this.props.t("send")}
              cancel={this.props.t("cancel")}
              nextStep={this.props.nextStep}
              loadTrue={this.loadTrue}
              loadFalse={this.loadFalse}
            />
            <form>
              <div className="row mx-5 my-1">
                <div className="col">
                  <span className="small float-left">
                    {this.props.t("creditType")}:*
                  </span>
                  <select
                    className={invalid("creditType")}
                    onChange={this.props.creditSelected}
                    value={values.creditType.value}
                  >
                    <option value="" hidden>
                      {this.props.t("choose")}
                    </option>
                    {credit.map((m, i) => (
                      <option value={m.slug} key={i}>
                        {m[this.props.locale]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row m-1">
                <div className="col">
                  {loading ? (
                    <div
                      className="spinner-border"
                      style={{
                        margin: "8rem",
                        width: "3rem",
                        height: "3rem",
                        role: "status",
                        color: "black",
                      }}
                    >
                      <span className="sr-only">{this.props.t("loading")}</span>
                    </div>
                  ) : (
                    <Element
                      locale={this.props.locale}
                      duration={this.props.t("duration")}
                      amount={this.props.t("amount")}
                      percentage={this.props.t("percentage")}
                      creditObj={creditObj}
                    />
                  )}
                </div>
              </div>
              <div className="row mb-5">
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-primary mx-3"
                    onClick={prevStep}
                  >
                    {this.props.t("previous")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mx-3"
                    onClick={this.toggle}
                  >
                    {this.props.t("next")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Fragment>
    );
  }
}

const MyModal = (props) => {
  const {
    modal,
    locale,
    toggle,
    credit,
    values,
    docs,
    sent,
    cancel,
    print,
    nextStep,
    loadTrue,
    loadFalse,
    contactHandler,
  } = props;

  const send = async () => {
    // toggle();
    loadTrue();
    const wp =
      "993" +
      values.workPhoneNumber.value
        .slice(7)
        .replace(/-/g, "")
        .replace(/ /g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "");
    const data = {
      name: values.firstName.value,
      surname: values.lastName.value,
      middlename: values.patronym.value,
      phone: "+993" + values.phoneNumber.value.slice(7, 18).replace(/-/g, ""),
      email: values.email.value,
      passport_type: values.passportSerial.value,
      passport_number: +values.passportNumber.value,
      passport_given_date: new Date(values.issuedDate.value).getTime() / 1000,
      birth_date: new Date(values.birthDate.value).getTime() / 1000,
      living_state: values.destrict.value,
      branch: values.bankBranch.value,
      work_state: values.workDistrict.value,
      work_district: values.workRegion.value,
      work_name: values.workAddress.value,
      work_salary: +values.salary.value.trim(),
      work_date: new Date(values.workStartDate.value).getTime() / 1000,
      work_position: values.occupation.value,
      work_phone: +wp,
      academic_degree: values.degree.value,
      marital_status: values.maritalStatus.value,
      emergency_name: values.contactPerson.value,
      emergency_phone:
        "+993" +
        values.contactPersonNumber.value.slice(7, 18).replace(/-/g, ""),
      credit: values.creditType.value,
    };

    axios
      .post(`${process.env.REACT_APP_ARSLAN}api/submit/form`, data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        if (res.data.status === 1) {
          loadFalse();
          contactHandler(
            res.data.contacts[0],
            res.data.applicationId,
            res.data.branchCode
          );
          nextStep();
        }
      })
      .catch((e) => {
        console.log(e);
        loadFalse();
      });
  };
  return (
    <Modal
      show={modal}
      onHide={toggle}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ fontSize: "1.25em !important" }}>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "1.25em !important" }}>{docs}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row print">
          <div className="col">
            <ol>
              {credit &&
                credit.requiredDocuments.map((m, i) => {
                  return <li key={i}>{m[locale]}</li>;
                })}
            </ol>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <button
            className={"btn btn-primary mx-3"}
            onClick={() => window.print()}
          >
            {print}
          </button>
          <button className={"btn btn-success mx-3"} onClick={send}>
            {sent}
          </button>
          <button className={"btn btn-secondary mx-3"} onClick={toggle}>
            {cancel}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const Element = ({ creditObj, percentage, amount, duration, locale }) => {
  if (creditObj) {
    return (
      <div className="row m-2">
        <div className="col">
          <p style={{ color: "black", fontSize: "1.25em" }}>{duration}</p>
          <h3 style={{ color: "black" }}>{creditObj.data.time}</h3>
        </div>
        <div className="col">
          <p style={{ color: "black", fontSize: "1.25em" }}>{amount}</p>
          <h3 style={{ color: "black" }}>{creditObj.data.amount}</h3>
        </div>
        <div className="col">
          <p style={{ color: "black", fontSize: "1.25em" }}>{percentage}</p>
          <h3 style={{ color: "black" }}>{creditObj.data.percentage}</h3>
        </div>
        <div
          style={{ color: "black", textAlign: "justify", fontSize: "1.15em" }}
          dangerouslySetInnerHTML={{
            __html: creditObj.condition[locale],
          }}
          className="row m-3"
        ></div>
      </div>
    );
  } else {
    return null;
  }
};

export default translate()(Step5);
