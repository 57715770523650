import React, { Component, Fragment } from "react";
import classes from "./input.module.css";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { translate } from "react-polyglot";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);
setDefaultLocale("en");
class Step2 extends Component {
  state = {
    date: "",
  };

  continue = (e) => {
    e.preventDefault();
    if (
      this.props.values.passportSerial.valid &&
      this.props.values.passportNumber.valid &&
      this.props.values.issuedDate.valid &&
      this.props.values.birthDate.valid &&
      this.props.values.destrict.valid &&
      this.props.values.bankBranch.valid
    ) {
      this.props.nextStep();
    } else {
      this.props.isStep2Valid();
    }
  };

  render() {
    const { values, handleChange, prevStep } = this.props;
    const invalid = (control) => {
      const inputClasses = [classes.InputElement, "small3"];
      if (values[control].touched) {
        if (!values[control].valid) {
          inputClasses.push(classes.Invalid);
          return inputClasses.join(" ");
        }
        return inputClasses.join(" ");
      }
      return inputClasses.join(" ");
    };

    return (
      <Fragment>
        <form>
          <div className="row m-5">
            <div className="col">
              <span className="small float-left">
                {this.props.t("passportSerial")}:*
              </span>
              <select
                className={invalid("passportSerial")}
                onChange={handleChange("passportSerial")}
                value={values.passportSerial.value}
              >
                <option value="" hidden>
                  {this.props.t("choose")}
                </option>
                {this.props.passport.map((m, i) => (
                  <option value={m.slug} key={i}>
                    {m.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <span className="small float-left">
                {this.props.t("passportNumber")}:*
              </span>
              <NumberFormat
                className={invalid("passportNumber")}
                onChange={handleChange("passportNumber")}
                value={values.passportNumber.value}
                displayType={"number"}
                format="######"
              />
            </div>
          </div>

          <div className="row m-5">
            <div className="row col mx-auto" style={{ marginRight: "10px" }}>
              <span
                className="small col"
                style={{ textAlign: "left", paddingLeft: 0 }}
              >
                {this.props.t("birthDate")}:*
              </span>

              <DatePicker
                locale={this.props.locale}
                required
                className={invalid("birthDate")}
                selected={values.birthDate.value}
                onChange={this.props.birthDateHandler}
                dateFormat="dd.MM.yyyy"
                isClearable
                maxDate={
                  new Date(
                    new Date().getFullYear() - 16,
                    new Date().getMonth(),
                    new Date().getDay()
                  )
                }
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
            <div className="row col mx-auto">
              <span
                className="small col"
                style={{ textAlign: "left", paddingLeft: 0 }}
              >
                {this.props.t("issuedDate")}:*
              </span>
              <DatePicker
                locale={this.props.locale}
                required
                className={invalid("issuedDate")}
                selected={values.issuedDate.value}
                onChange={this.props.issuedDateHandler}
                dateFormat="dd.MM.yyyy"
                minDate={
                  new Date(
                    new Date(values.birthDate.value).getFullYear() + 16,
                    new Date(values.birthDate.value).getMonth(),
                    new Date(values.birthDate.value).getDay()
                  )
                }
                maxDate={new Date()}
                isClearable
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <div className="row m-5">
            <div className="col">
              <span className="small float-left">
                {this.props.t("livingState")}:*
              </span>
              <select
                className={invalid("destrict")}
                onChange={this.props.districtHandle}
                value={values.destrict.value}
              >
                <option value="" hidden>
                  {this.props.t("choose")}
                </option>
                {this.props.states.map((m, i) => (
                  <option value={m.slug} key={i}>
                    {m[this.props.locale]}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <span className="small float-left">
                {this.props.t("bankBranch")}:*
              </span>
              <select
                className={invalid("bankBranch")}
                onChange={handleChange("bankBranch")}
                value={values.bankBranch.value}
                disabled={this.props.loadingBranches}
              >
                <option value="" hidden>
                  {this.props.loadingBranches
                    ? this.props.t("loading")
                    : this.props.t("choose")}
                </option>
                {this.props.branches.map((m, i) => (
                  <option value={m.slug} key={i}>
                    {m.name}
                  </option>
                ))}
              </select>
              <span className="small2 float-right">
                {this.props.t("wantedBranch")}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-primary mx-3"
                onClick={prevStep}
              >
                {this.props.t("previous")}
              </button>
              <button
                type="button"
                className="btn btn-primary mx-3"
                onClick={this.continue}
              >
                {this.props.t("next")}
              </button>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default translate()(Step2);
