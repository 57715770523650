import React from "react";
import image from "./../zoloto.webp";

const Nav = (props) => {
  return (
    <div>
    	<nav className='navbar'>
	      <a href="https://tnbk.tm" className='navbar-brand mb-0 h1' style={{ color: "black", fontSize: "24px",fontWeight: "300" }}>
	        Türkmenistan | banky
	      </a>
	      {props.locale === "tm" ? (
	        <button
	          style={{ color: "black", border: "2px solid black" }}
	          className='myButton'
	          onClick={props.changeLocale}
	        >
	          RU
	        </button>
	      ) : (
	        <button
	          style={{ color: "black", border: "2px solid black" }}
	          className='myButton'
	          onClick={props.changeLocale}
	        >
	          TM
	        </button>
	      )} 
	    </nav>
	    <img src={image} alt="surat" style={{ height: "50px", width: "auto", marginTop: "10px" }}/>
    </div>
  );
};

export default Nav;
