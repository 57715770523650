import React, { Component } from "react";
import "./bar.css";
import { translate } from "react-polyglot";

class StepBar extends Component {
  render() {
    const { values, step } = this.props;
    const isStep2 =
      values.firstName.valid &&
      values.lastName.valid &&
      values.phoneNumber.valid;
    const isStep3 =
      values.passportSerial.valid &&
      values.passportNumber.valid &&
      values.issuedDate.valid &&
      values.birthDate.valid &&
      values.destrict.valid &&
      values.bankBranch.valid &&
      isStep2;
    const isStep4 =
      values.workStartDate.valid &&
      values.occupation.valid &&
      values.workDistrict.valid &&
      values.workRegion.valid &&
      values.workAddress.valid &&
      values.salary.valid &&
      values.workPhoneNumber.valid &&
      isStep3;
    const isStep5 =
      values.degree.valid &&
      values.maritalStatus.valid &&
      values.contactPerson.valid &&
      values.contactPersonNumber.valid &&
      isStep4;
    const active = (s) => {
      if (s === step) {
        return "btn btn-primary";
      } else {
        return "btn btn-secondary";
      }
    };
    return (
      <div className='steps'>
        <button className={active(1)} onClick={() => this.props.stepHandler(1)}>
          {this.props.t("step1")}
        </button>
        <button
          disabled={!isStep2}
          className={active(2)}
          onClick={() => this.props.stepHandler(2)}
        >
          {this.props.t("step2")}
        </button>
        <button
          disabled={!isStep3}
          className={active(3)}
          onClick={() => this.props.stepHandler(3)}
        >
          {this.props.t("step3")}
        </button>
        <button
          disabled={!isStep4}
          className={active(4)}
          onClick={() => this.props.stepHandler(4)}
        >
          {this.props.t("step4")}
        </button>
        <button
          disabled={!isStep5}
          className={active(5)}
          onClick={() => this.props.stepHandler(5)}
        >
          {this.props.t("step5")}
        </button>
      </div>
    );
  }
}

export default translate()(StepBar);
