import React, { Component, Fragment } from "react";
import { translate } from "react-polyglot";
class Step5 extends Component {
  render() {
    return (
      <Fragment>
        <div className='App print' style={{ marginTop: "12rem" }}>
          <h3 style={{ color: "black", fontSize: "1.5em", fontWeight: "300" }}>{this.props.t("success")}</h3>
          <div>
            <h3 style={{ color: "black", fontSize: "1.5em", fontWeight: "300" }}>
              {this.props.t("tel")}
              {this.props.contact.phone}
            </h3>
            <h3 style={{ color: "black", fontSize: "1.5em", fontWeight: "300" }}>
              {this.props.t("responsible")}
              {this.props.contact.name}
            </h3>
            <h3 style={{ color: "black", fontSize: "1.5em", fontWeight: "300" }}>
              {this.props.t("refNumber")}
              {this.props.contact.branch}-{this.props.contact.orderNumber}
            </h3>
          </div>
          <h3 style={{ color: "black", fontSize: "1.5em" }}>
            <button
              className='btn btn-primary mx-3'
              onClick={() => {
                window.print();
              }}
            >
              {this.props.t("print")}
            </button>
            <a href='/' className='mx-3'>
              {this.props.t("home")}
            </a>
          </h3>
        </div>
      </Fragment>
    );
  }
}

export default translate()(Step5);
