import React, { Component, Fragment } from "react";

import classes from "./input.module.css";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { translate } from "react-polyglot";
import { registerLocale, setDefaultLocale } from "react-datepicker";

// import MaskedInput from "react-text-mask";
// import InputMask from "react-input-mask";

import ru from "date-fns/locale/ru";

registerLocale("ru", ru);

setDefaultLocale("en");

class Step3 extends Component {
  state = {
    activeNameIndex: 0,
    inputFocused: false,
  };
  onKeyDownHandler = (key) => {
    switch (key.keyCode) {
      case 13:
        if (this.props.addressSuggestions.length) {
          this.props.addressSelected(
            this.props.addressSuggestions[this.state.activeNameIndex].name
          );
          this.setState({ inputFocused: false });
        }
        break;
      case 40:
        key.preventDefault();
        if (
          this.state.activeNameIndex <
          this.props.addressSuggestions.length - 1
        ) {
          this.setState({
            activeNameIndex: this.state.activeNameIndex + 1,
          });
        }
        break;
      case 38:
        key.preventDefault();
        if (this.state.activeNameIndex > 0) {
          this.setState({
            activeNameIndex: this.state.activeNameIndex - 1,
          });
        }
        break;
      default:
        this.setState((state) => ({
          inputFocused: true,
          activeNameIndex: 0,
        }));
        break;
    }
  };
  continue = (e) => {
    e.preventDefault();
    if (
      this.props.values.workStartDate.valid &&
      this.props.values.occupation.valid &&
      this.props.values.workDistrict.valid &&
      this.props.values.workRegion.valid &&
      this.props.values.workAddress.valid &&
      this.props.values.salary.valid &&
      this.props.values.workPhoneNumber.valid
    ) {
      this.props.nextStep();
    } else {
      this.props.isStep3Valid();
    }
  };

  render() {
    const {
      values,
      handleChange,
      prevStep,
      regionCode,
      addressChanged,
      addressSelected,
      addressSuggestions,
    } = this.props;

    const invalid = (control) => {
      const inputClasses = [classes.InputElement, "small3"];
      if (values[control].touched) {
        if (!values[control].valid) {
          inputClasses.push(classes.Invalid);
          return inputClasses.join(" ");
        }
        return inputClasses.join(" ");
      }
      return inputClasses.join(" ");
    };

    return (
      <Fragment>
        <form>
          <div className="row m-5">
            <div className="col">
              <span className="small float-left">
                {this.props.t("workStartDate")}:*
              </span>
              <DatePicker
                locale={this.props.locale}
                required
                className={invalid("workStartDate")}
                selected={values.workStartDate.value}
                onChange={this.props.workStartDateHandler}
                dateFormat="dd.MM.yyyy"
                isClearable
                maxDate={new Date()}
                minDate={
                  new Date(
                      new Date(this.props.birthday).getFullYear() + 16,
                      new Date(this.props.birthday).getMonth(),
                      new Date(this.props.birthday).getDay()
                  )
                }
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
            <div className="col">
              <span className="small float-left">
                {this.props.t("occupation")}:*
              </span>
              <input
                type="text"
                className={invalid("occupation")}
                value={values.occupation.value}
                onChange={handleChange("occupation")}
              />
            </div>
          </div>

          <div className="row m-5">
            <div className="col">
              <span className="small float-left">
                {this.props.t("workState")}:*
              </span>
              <select
                className={invalid("workDistrict")}
                onChange={this.props.stateChanged}
                value={values.workDistrict.value}
              >
                <option value="" hidden>
                  {this.props.t("choose")}
                </option>
                {this.props.states.map((m, i) => (
                  <option value={m.slug} key={i}>
                    {m[this.props.locale]}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <span className="small float-left">
                {this.props.t("workReqion")}:*
              </span>
              <select
                className={invalid("workRegion")}
                onChange={this.props.regionChanged}
                disabled={this.props.regionLoading}
                value={values.workRegion.value}
              >
                <option value="" hidden>
                  {this.props.regionLoading
                    ? this.props.t("loading")
                    : this.props.t("choose")}
                </option>
                {this.props.districtList.map((m, i) => (
                  <option value={m.slug} key={i}>
                    {m[this.props.locale]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row m-5">
            <div className="col">
              <span className="small float-left">
                {this.props.t("workAddress")}:*
              </span>
              <div className="inputContainer">
                <input
                  type="text"
                  value={values.workAddress.value}
                  className={invalid("workAddress")}
                  onChange={addressChanged}
                  onFocus={() => {
                    this.setState({ inputFocused: true });
                  }}
                  onBlur={(e) => {
                    this.setState({ inputFocused: false });
                  }}
                  onKeyDown={this.onKeyDownHandler}
                />
                <div
                  className="suggestions"
                  style={{
                    display: this.state.inputFocused ? "block" : "none",
                  }}
                >
                  <ul>
                    {addressSuggestions.map((item, i) => (
                      <li
                        key={i}
                        onMouseDown={() => {
                          addressSelected(item.name);
                        }}
                        style={{
                          backgroundColor:
                            this.state.activeNameIndex === i ? "#eee" : "",
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* <AsyncCreatableSelect
                formatCreateLabel={(inputValue) => inputValue}
                name="workAddress"
                noOptionsMessage={() => this.props.t("notFound")}
                loadingMessage={() => this.props.t("loading")}
                styles={customStyles}
                isClearable={true}
                value={values.workAddress.objValue}
                cacheOptions
                defaultOptions
                loadOptions={addressAsyncLoad}
                onChange={(e) => {
                  addressChanged(e);
                }}
              /> */}
            </div>
            <div className="col">
              <span className="small float-left">
                {this.props.t("salary")}:*
              </span>

              <NumberFormat
                className={invalid("salary")}
                onChange={handleChange("salary")}
                value={values.salary.value}
                displayType={"number"}
                format="#####"
              />
            </div>
          </div>
          <div className="row m-5">
            <div className="col-6">
              <span className="small float-left">
                {this.props.t("workPhoneNumber")}:*
              </span>
              {/* <MaskedInput
                className={invalid("workPhoneNumber")}
                mask={[
                  "(",
                  /\d/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              /> */}
              {/* <InputMask
                className={invalid("workAddress")}
                mask={`+(993) ${regionCode ? "(" + regionCode + ")" : ""} ${
                  regionCode && regionCode.toString().length === 3
                    ? "#-##-##"
                    : "##-##-##"
                }`}
                maskChar={null}
                value={this.state.value}
                onChange={this.onChange}
                beforeMaskedValueChange={this.beforeMaskedValueChange}
              /> */}
              <NumberFormat
                type={"tel"}
                className={invalid("workPhoneNumber")}
                onChange={handleChange("workPhoneNumber")}
                value={values.workPhoneNumber.value}
                format={`+(993) ${regionCode ? "(" + regionCode + ")" : ""} ${
                  regionCode && regionCode.toString().length === 3
                    ? "#-##-##"
                    : "##-##-##"
                }`}
                mask={"_"}
                allowEmptyFormatting
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-primary mx-3"
                onClick={prevStep}
              >
                {this.props.t("previous")}
              </button>
              <button
                type="button"
                className="btn btn-primary mx-3"
                onClick={this.continue}
              >
                {this.props.t("next")}
              </button>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default translate()(Step3);
