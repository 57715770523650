const messages = {
  tm: {
    name: "Ady",
    surname: "Familiýasy",
    lastName: "Atasynyň ady",
    phoneNumber: "Telefon belgiňiz",
    email: "Elektron poçtaňyz",
    likeInPassportName: "Pasportda görkezilişi ýaly dolduryň",
    likeInPassportLastName:
      "Pasportda görkezilişi ýaly dolduryň. Eger-de ýok bolsa boş goýuň",
    next: "Indiki",
    previous: "Yza",
    passportSerial: "Pasport seriýa",
    passportNumber: "Pasport belgisi",
    issuedDate: "Pasport berilen senesi",
    birthDate: "Doglan senesi",
    livingState: "Ýaşaýan welaýatyňyzy saýlaň",
    bankBranch: "Bank şahamçasyny saýlaň",
    wantedBranch: "Karz almak isleýän şahamçaňyzy saýlaň",
    choose: "Saýla",
    loading: "Ýüklenýär...",
    workStartDate: "Işe başlan senäňiz",
    occupation: "Käriňiz(Wezipäňiz)",
    workState: "Işleýän welaýatyňyzy saýlaň",
    workReqion: "Işleýän etrabyňyzy saýlaň",
    workAddress: "Edaranyň/Kärhananyň ady ýa-da salgyt belgisi",
    salary: "Aýlygyňyzyň möçberi (manat)",
    workPhoneNumber:
      "Iş ýeriňiziň buhgalter hasabatlar bölüminiň telefon belgisi",
    degree: "Bilim derejäňiz",
    maritalStatus: "Maşgala ýagdaýyňyz",
    emergencyPerson:
      "Esasy telefon belgiňiziň elýeter bolmadyk wagtynda, siz bilen habarlaşmak üçin adamyň ady we telefon belgisi",
    emergencyNumber: "Telefon belgisi",
    creditType: "Karzyň görnüşini saýlaň",
    duration: "Möhleti (aý)",
    amount: "Karzyň möçberi (manat)",
    percentage: "Göterim (ýyllyk)",
    print: "Çap et",
    send: "Ugrat",
    cancel: "Yza",
    docs:
      "Banka geleniňizde ýanyňyz bilen şu resminamalaryň asyl nusgasyny we kopiýasyny getiriň",
    step1: "Şahsy",
    step2: "Pasport",
    step3: "Iş barada",
    step4: "Goşmaça",
    step5: "Kredit",
    success:
      "Arzaňyz üstünlikli ýatda saklanyldy we jogapkär işgäre seretmek üçin tabşyryldy.",
    home: "Esasy sahypa",
    tel: "Habarlaşmak üçin tel:  ",
    responsible: "Jogapkär işgär:  ",
    refNumber: "Arzanyň belgisi:  ",
    emailClean: "Elektron poçta:  ",
    notFound: "Tapylmady",
  },
  ru: {
    name: "Имя",
    surname: "Фамилия",
    lastName: "Отчество",
    phoneNumber: "Мобильный телефон",
    email: "Электронная почта",
    likeInPassportName: "Укажите точно, как в паспорте",
    likeInPassportLastName:
      "Укажите точно, как в паспорте. Если отчество отсутствует оставьте пустым",
    next: "Продолжить",
    previous: "Вернуться",
    passportSerial: "Серия паспорта",
    passportNumber: "Номер паспорта",
    issuedDate: "Дата выдачи",
    birthDate: "Дата рождения",
    livingState: "Регион проживания",
    bankBranch: "Подразделение банка",
    wantedBranch:
      "Укажите подразделение, в котором Вы намерены получить кредит",
    choose: "Выбрать",
    loading: "Загрузка...",
    workStartDate: "Дата устройства на текущее место работы",
    occupation: "Должность",
    workState: "Mесто работы (Велаят)",
    workReqion: "Город",
    workAddress: "Название организации или её налоговый код",
    salary: "Размер заработной платы по месту работы (манат)",
    workPhoneNumber:
      "Основной телефон вашей работы или телефон отдела кадров/бухгалтерии",
    degree: "Ваше образование",
    maritalStatus: "Семейное положение",
    emergencyPerson:
      "Данные человека, телефон которого вы указываете на случай недоступности вашего основного, кроме телефона супруга/супруги",
    emergencyNumber: "Мобильный телефон",
    creditType: "Выберите вид кредита",
    duration: "Срок (месяц)",
    amount: "Сумма (манат)",
    percentage: "Ставка (год)",
    print: "Печатать",
    send: "Отправить",
    cancel: "Отменить",
    docs:
      "При посещении банка иметь при себе оригиналы и копии следуюших документов",
    step1: "Персональные данные",
    step2: "Паспортные данные",
    step3: "Сведения о работе",
    step4: "Дополнительные сведения",
    step5: "Вид кредита",
    success:
      "Заявка успешно сохранена и передана на рассмотрение кредитному сотруднику, который свяжется с Вами в ближайшее время по указанному Вами телефону.",
    home: "Перейти на главную страницу",
    tel: "Телефон для связи:  ",
    responsible: "Ответственный:  ",
    refNumber: "Номер заявки:  ",
    emailClean: "Электронная почта:  ",
    notFound: "Не найдено",
  },
};

export default messages;
