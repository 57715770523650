import React, { Component, Fragment, createRef } from "react";
import classes from "./input.module.css";
import NumberFormat from "react-number-format";
import { translate } from "react-polyglot";
class Step1 extends Component {
  state = {
    activeNameIndex: 0,
    inputFocused: false,
    lastNameFocused: false,
    patronymFocused: false,
  };
  nameInput = createRef();
  continue = (e) => {
    e.preventDefault();
    if (
      this.props.values.firstName.valid &&
      this.props.values.lastName.valid &&
      this.props.values.phoneNumber.valid
    ) {
      this.props.nextStep();
    } else {
      this.props.isStep1Valid();
    }
  };
  onKeyDownHandler = (key) => {
    switch (key.keyCode) {
      case 13:
        if (this.props.nameSuggestions.length) {
          this.props.nameSelected(
            this.props.nameSuggestions[this.state.activeNameIndex].name
          );
          this.setState({ inputFocused: false });
        }
        break;
      case 40:
        key.preventDefault();
        if (
          this.state.activeNameIndex <
          this.props.nameSuggestions.length - 1
        ) {
          this.setState({
            activeNameIndex: this.state.activeNameIndex + 1,
          });
        }
        break;
      case 38:
        key.preventDefault();
        if (this.state.activeNameIndex > 0) {
          this.setState({
            activeNameIndex: this.state.activeNameIndex - 1,
          });
        }
        break;
      default:
        this.setState((state) => ({
          inputFocused: true,
          activeNameIndex: 0,
        }));
        break;
    }
  };
  onKeyDownHandlerLastName = (key) => {
    switch (key.keyCode) {
      case 13:
        if (this.props.surnameSuggestions.length) {
          this.props.surnameSelected(
            this.props.surnameSuggestions[this.state.activeNameIndex].name
          );
          this.setState({ lastNameFocused: false });
        }
        break;
      case 40:
        key.preventDefault();
        if (
          this.state.activeNameIndex <
          this.props.surnameSuggestions.length - 1
        ) {
          this.setState({
            activeNameIndex: this.state.activeNameIndex + 1,
          });
        }
        break;
      case 38:
        key.preventDefault();
        if (this.state.activeNameIndex > 0) {
          this.setState({
            activeNameIndex: this.state.activeNameIndex - 1,
          });
        }
        break;
      default:
        this.setState((state) => ({
          lastNameFocused: true,
          activeNameIndex: 0,
        }));
        break;
    }
  };

  onKeyDownHandlerPatronym = (key) => {
    switch (key.keyCode) {
      case 13:
        if (this.props.middleSuggestions.length) {
          this.props.middleNameSelected(
            this.props.middleSuggestions[this.state.activeNameIndex].name
          );
          this.setState({ patronymFocused: false });
        }
        break;
      case 40:
        key.preventDefault();
        if (
          this.state.activeNameIndex <
          this.props.middleSuggestions.length - 1
        ) {
          this.setState({
            activeNameIndex: this.state.activeNameIndex + 1,
          });
        }
        break;
      case 38:
        key.preventDefault();
        if (this.state.activeNameIndex > 0) {
          this.setState({
            activeNameIndex: this.state.activeNameIndex - 1,
          });
        }
        break;
      default:
        this.setState((state) => ({
          patronymFocused: true,
          activeNameIndex: 0,
        }));
        break;
    }
  };
  render() {
    const {
      values,
      handleChange,
      nameChanged,
      surnameChanged,
      nameSelected,
      surnameSelected,
      middleNameSelected,
      middleName,
      nameSuggestions,
      surnameSuggestions,
      middleSuggestions,
    } = this.props;

    const invalid = (control) => {
      const inputClasses = [classes.InputElement, "small3"];
      if (values[control].touched) {
        if (!values[control].valid) {
          inputClasses.push(classes.Invalid);
          return inputClasses.join(" ");
        }
        return inputClasses.join(" ");
      }
      return inputClasses.join(" ");
    };

    return (
      <Fragment>
        <form>
          <div className="row m-5">
            <div className="col">
              <span className="small float-left">{this.props.t("name")}:*</span>
              <div className="inputContainer">
                <input
                  type="text"
                  value={values.firstName.value}
                  className={invalid("firstName")}
                  onChange={nameChanged}
                  onFocus={() => {
                    this.setState({ inputFocused: true });
                  }}
                  onBlur={(e) => {
                    this.setState({ inputFocused: false });
                  }}
                  onKeyDown={this.onKeyDownHandler}
                />
                <div
                  className="suggestions"
                  style={{
                    display: this.state.inputFocused ? "block" : "none",
                  }}
                >
                  <ul>
                    {nameSuggestions.map((item, i) => (
                      <li
                        key={i}
                        onMouseDown={() => {
                          nameSelected(item.name);
                        }}
                        style={{
                          backgroundColor:
                            this.state.activeNameIndex === i ? "#eee" : "",
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* <AsyncCreatableSelect
                formatCreateLabel={(inputValue) => inputValue}
                autoFocus={true}
                name="firstName"
                noOptionsMessage={() => this.props.t("notFound")}
                loadingMessage={() => this.props.t("loading")}
                styles={customStyles}
                isClearable={true}
                value={values.firstName.objValue}
                cacheOptions
                defaultOptions
                loadOptions={nameAsyncLoad}
                onChange={(e) => {
                  nameChanged(e);
                }}
              /> */}
              <span className="small2 float-right">
                {this.props.t("likeInPassportName")}
              </span>
            </div>
            <div className="col">
              <span className="small float-left">
                {this.props.t("surname")}:*
              </span>
              <div className="inputContainer">
                <input
                  type="text"
                  value={values.lastName.value}
                  className={invalid("lastName")}
                  onChange={surnameChanged}
                  onFocus={() => {
                    this.setState({ lastNameFocused: true });
                  }}
                  onBlur={(e) => {
                    this.setState({ lastNameFocused: false });
                  }}
                  onKeyDown={this.onKeyDownHandlerLastName}
                />
                <div
                  className="suggestions"
                  style={{
                    display: this.state.lastNameFocused ? "block" : "none",
                  }}
                >
                  <ul>
                    {surnameSuggestions.map((item, i) => (
                      <li
                        key={i}
                        onMouseDown={() => {
                          surnameSelected(item.name);
                        }}
                        style={{
                          backgroundColor:
                            this.state.activeNameIndex === i ? "#eee" : "",
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* <AsyncCreatableSelect
                formatCreateLabel={(inputValue) => inputValue}
                name="lastName"
                noOptionsMessage={() => this.props.t("notFound")}
                loadingMessage={() => this.props.t("loading")}
                styles={customStyles}
                isClearable={true}
                value={values.lastName.objValue}
                cacheOptions
                defaultOptions
                loadOptions={surnameAsyncLoad}
                onChange={(e) => {
                  surnameChanged(e);
                }}
              /> */}
              <span className="small2 float-right">
                {this.props.t("likeInPassportName")}
              </span>
            </div>
            <div className="col">
              <span className="small float-left">
                {this.props.t("lastName")}:
              </span>

              <div className="inputContainer">
                <input
                  type="text"
                  value={values.patronym.value}
                  className={invalid("patronym")}
                  onChange={middleName}
                  onFocus={() => {
                    this.setState({ patronymFocused: true });
                  }}
                  onBlur={(e) => {
                    this.setState({ patronymFocused: false });
                  }}
                  onKeyDown={this.onKeyDownHandlerPatronym}
                />
                <div
                  className="suggestions"
                  style={{
                    display: this.state.patronymFocused ? "block" : "none",
                  }}
                >
                  <ul>
                    {middleSuggestions.map((item, i) => (
                      <li
                        key={i}
                        onMouseDown={() => {
                          middleNameSelected(item.name);
                        }}
                        style={{
                          backgroundColor:
                            this.state.activeNameIndex === i ? "#eee" : "",
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <span className="small2 float-left">
                {this.props.t("likeInPassportLastName")}
              </span>
            </div>
          </div>

          <div className="row m-5">
            <div className="col">
              <span className="small float-left">
                {this.props.t("phoneNumber")}:*
              </span>
              <NumberFormat
                className={invalid("phoneNumber")}
                onChange={handleChange("phoneNumber")}
                value={values.phoneNumber.value}
                allowEmptyFormatting
                displayType={"number"}
                format="+(993) 6#-##-##-##"
                mask={"_"}
              />
            </div>
            <div className="col">
              <span className="small float-left">
                {" "}
                {this.props.t("email")}:
              </span>
              <input
                type="email"
                className={invalid("email")}
                placeholder="credit@gmail.com"
                value={values.email.value}
                onChange={handleChange("email")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.continue}
              >
                {this.props.t("next")}
              </button>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default translate()(Step1);
